import {NgModule} from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
//Guards
import { YaEstaLogueadoGuard } from '@guards/ya-esta-logueado.guard';
import { LoginGuard } from '@guards/login.guard';
//Pages
import {MainComponent} from '@modules/main/main.component';
import {LoginComponent} from '@modules/login/login.component';
import { InicioComponent } from '@pages/inicio/inicio.component';
import { MovimientosComponent } from '@pages/movimientos/movimientos.component';
import { PagoComponent } from '@pages/pago/pago.component';
import { MiCuentaComponent } from '@pages/mi-cuenta/mi-cuenta.component';
import { CambiarPasswordComponent } from '@pages/cambiar-password/cambiar-password.component';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./modules/main/main.module').then(m => m.MainModule),
        canActivate: [LoginGuard],
    },
    {
        path: 'login',
        loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule),
        canActivate: [YaEstaLogueadoGuard],
        pathMatch: "full",
    },
    {
        path: 'recuperar-password',
        loadChildren: () => import('./modules/recuperar-password/recuperar-password.module').then(m => m.RecuperarPasswordModule),
        //canActivate: [YaEstaLogueadoGuard],
        pathMatch: "full",
    },
    {
        path: 'nueva-cuenta',
        loadChildren: () => import('./modules/nueva-cuenta/nueva-cuenta.module').then(m => m.NuevaCuentaModule),
        //canActivate: [YaEstaLogueadoGuard],
        pathMatch: "full",
    },
    {path: '**', redirectTo: ''}
];

@NgModule({
    imports: [RouterModule.forRoot(routes,  {preloadingStrategy : PreloadAllModules})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
