import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { IComercio, Usuario } from "../models/usuario.model";
import { environment } from "./../../environments/environment";
import { ApiCommonResponse } from "@/interfaces/responses/common/ApiResponse";
import { IAplicacion } from "@/models/aplicacion.model";
import { tap } from "rxjs/operators";
import { LoginDTO } from "@/interfaces/dtos/login.dto";
import { Menu } from "@/models/menu.model";
import { Store } from "@ngrx/store";
import { AppState } from "@/store/state";
import { deleteUsuarioLogueado, saveUsuarioLogueado } from "@/store/usuario-logueado/actions";
import { MatDialog } from "@angular/material/dialog";
import { deleteAppName, saveAppName } from "@/store/appname/actions";
import { AppNameState } from "@/store/appname/state";

@Injectable({
  providedIn: "root",
})
export class AutentificacionService {
  url = environment.url;

  constructor(
    private http: HttpClient,
    private router: Router,
    private store: Store<AppState>,
    private dialogRef: MatDialog
  ) { }

  login(email: string, password: string, cuit: string, app_name: string) {
    let data: LoginDTO = {
      email,
      password,
      cuit,
      app_name
    }

    return this.http.post<ApiCommonResponse & { token: string }>(this.url + "web/login", data).pipe(tap(res => {
      if(res.status == 'ok'){
        localStorage.setItem('app_name', app_name)
      }
    }));
  }

  me() {
    return this.http.get<ApiCommonResponse & { usuario: Usuario, menus: Menu[], comercio : IComercio}>(this.url + "web/me").pipe(tap((res) => {
      if (res.status == "ok") {
        const usuario : Usuario = { ...res.usuario, menus: res.menus, comercio: res.comercio };
        this.store.dispatch(saveUsuarioLogueado({ usuario }));
        if(localStorage.getItem('app_name')){
          this.store.dispatch(saveAppName({appname : localStorage.getItem('app_name') as AppNameState}))
        }
      } else {
        this.logOut();
      }
    }));
  }

  logOut() {
    this.http.post<ApiCommonResponse>(this.url + "back/logout", {}).subscribe();
    this.store.dispatch(deleteUsuarioLogueado());
    this.store.dispatch(deleteAppName());
    localStorage.clear();
    sessionStorage.clear()
    this.router.navigate(["/login"]);
  }

  expulsar() {
    this.store.dispatch(deleteUsuarioLogueado());
    this.store.dispatch(deleteAppName());
    localStorage.clear();
    sessionStorage.clear()
    this.dialogRef.closeAll();
    this.router.navigate(["/login"]);
  }

  estaLogueado() {
    return localStorage.getItem("access_token") !== null;
  }

  obtenerAppNames(email: string, cuit: string, source: string) {
    return this.http.get<ApiCommonResponse & { aplicaciones: IAplicacion[] }>(this.url + "web/aplicaciones", { params: { email, cuit, source } });
  }

  changePassword(password_anterior: string, password: string) {
    return this.http.put<ApiCommonResponse>(this.url + 'web/password', { password_anterior, password });
  }

}
