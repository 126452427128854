import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import { deleteAppName, saveAppName } from "./actions";

export const appnameReducer = createReducer(
  initialState,
  on(saveAppName, (state, props) => {
    return props.appname
  }),
  on(deleteAppName, () => {
    return null
  })
);
